.about-container {
	display: flex;
	gap: 4rem;
	padding: 3rem 0;
	max-width: 1200px;
	margin: 0 auto;
  }
  
  .about-left {
	width: 30%;
	display: flex;
	flex-direction: column;
	gap: 2rem;
  }
  
  .about-image {
	width: 100%;
	max-width: 270px;
	border-radius: 10%;
  }
  
  .contact-info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
  }
  
  .email-link {
	color: #333;
	text-decoration: none;
	font-weight: 600;
	font-size: 1.1rem;
  }
  
  .social-links {
	display: flex;
	width: 100%;
	gap: 1.5rem;
	align-items: center;
  }
  
  .icon-image {
	width: 22px;
	height: 22px;
	transition: opacity 0.2s ease;
  }
  
  .social-link {
	color: #333;
	text-decoration: none;
	font-size: 1.1rem;
	font-weight: 500;
  }
  
  .social-link:hover .icon-image {
	opacity: 0.7;
  }
  
  .about-right {
	width: 70%;
  }
  
  .about-name {
	font-size: 3rem;
	font-weight: bold;
	margin-bottom: 1rem;
  }
  
  .about-text {
	font-size: 1.1rem;
	line-height: 1.6;
  }
  
  .AboutText {
	font-size: 1.1rem;
	line-height: 1.6;
  }
  
  .abt_a {
	color: maroon;
	text-decoration: none;
  }
  
  .abt_a:hover {
	text-decoration: underline;
  }
  
  @media (max-width: 768px) {
	.about-container {
	  flex-direction: column;
	  gap: 2rem;
	}
  
	.about-name {
	  font-size: 1.8rem;
	  text-align: center;
	  margin-bottom: 1.5rem;
	}
	
	.about-left {
	  width: 100%;
	  align-items: center;
	}
	
	.about-right {
	  width: 100%;
	}
  
	.about-image {
	  max-width: 200px;
	}
  
	.contact-info {
	  align-items: center;
	  width: 100%;
	}
  
	.social-links {
	  justify-content: center;
	}
  
	.AboutText {
	  font-size: 1rem;
	  padding: 0 1rem;
	}
  
	.about-text {
	  font-size: 1rem;
	}
  }