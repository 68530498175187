.App-logo {
  height: 40vmin; 
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  /* font-size: 18px; */
  color: white;
}

.App-link {
  color: #61dafb;
}

.App {
  display: flex;
  text-align: left;
  align-items: left;
  font-family: 'avenir';
  background-color: white;

  @media screen and (max-width: 720px) {
    flex-direction: column;
    align-items: center;
  }
}

.App {
  text-align: left;
  font-family: 'avenir';
  background-color: white;
}

.floating-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding: 0.2rem 0;
  transition: all 0.3s ease;
  z-index: 1000;
  font-family: 'avenir';
  height: 80px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid maroon;
}

.nav-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  width: 100%;
}

.nav-links {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.nav-item {
  border: none;
  background: none;
  padding: 0.5rem 1.3rem;
  font-size: 1.1rem;
  color: #333;
  cursor: pointer;
  font-family: 'avenir';
  transition: all 0.2s ease;
  border-radius: 20px;
  position: relative;
}

.nav-item:after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background-color: maroon;
  transition: all 0.3s ease;
}

.nav-item:hover {
  color: maroon;
}

.nav-item:hover:after {
  width: 80%;
  left: 10%;
}

/* Hamburger button */
.hamburger-button {
  display: none;
  border: none;
  background: none;
  padding: 15px;
  cursor: pointer;
  position: relative;
  z-index: 1001;
}

.hamburger-line,
.hamburger-line:before,
.hamburger-line:after {
  display: block;
  width: 24px;
  height: 2px;
  background-color: #333;
  position: relative;
  transition: all 0.3s ease;
}

.hamburger-line:before,
.hamburger-line:after {
  content: '';
  position: absolute;
  left: 0;
}

.hamburger-line:before {
  top: -8px;
}

.hamburger-line:after {
  bottom: -8px;
}

/* Hamburger animation */
.hamburger-line.open {
  background-color: transparent;
}

.hamburger-line.open:before {
  transform: rotate(45deg);
  top: 0;
}

.hamburger-line.open:after {
  transform: rotate(-45deg);
  bottom: 0;
}

/* Mobile overlay */
.mobile-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Mobile styles */
@media (max-width: 768px) {
  .hamburger-button {
    display: block;
    position: absolute;
    left: 1rem;
  }

  .nav-links {
    position: fixed;
    top: 88px;
    left: -100%;
    width: 250px;
    height: calc(100vh - 88px);
    background-color: white;
    flex-direction: column;
    padding: 2rem;
    gap: 1.5rem;
    transition: all 0.3s ease;
    z-index: 1000;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }

  .nav-links.open {
    left: 0;
  }

  .mobile-overlay {
    display: block;
  }

  .nav-item {
    width: 100%;
    text-align: left;
    padding: 1rem;
  }

  .nav-item:after {
    display: none;
  }

  .nav-item:hover {
    background-color: #f5f5f5;
  }
}

.section-divider {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  height: 1px;
  background-color: #e5e5e5;
}


.page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 5rem 1rem 1rem 1rem;
}

.header-content {
  float:left;
  justify-content: center;
  padding-top: 14vh;
  padding-bottom: 5vh;
  /* padding-left: 5vh; */
  font-family: 'avenir';
  background-color: rgb(239, 237, 227);
  background-color: white;
  width: 35%;
  height: 100%;
}

.name {
  font-size: 24px;
}
.main-text {
  display: flex;
  flex-flow: row wrap;
  padding-top: 4vh;
  padding-bottom: 4vh;
  align-self: center;

  align-items: center;
  justify-content: center;
  font-family: 'avenir';
  /* margin-left: auto; */
  /* margin-right: auto; */

  @media screen and (max-width: 720px) {
    flex-flow: nowrap;
    justify-content: start;
  }
}

.navBar {
  text-align: left;
  margin: auto;
  padding-left: 3vh;
  padding-right: 8vh;
}

.nav {
  text-decoration: none;
  color: black;
  font-size: 20px;
  
}

.v1 {
  border-left: 1px solid black;
}

.leftMain {
  flex-flow: column wrap;
  display: flex;
  text-align: right;
  align-items: right;
  padding-right: 3vh;
}

/* LOCATION */

.location {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* flex-flow: row wrap; */
  align-self: center;
  color: var(--color-6);
}

#locationtag {
  display: flex;
  align-self: center;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  
}

.iconBox {
  padding-right: 1vh;
}

/* SOCIAL ICONS */
.LinksContainer {
  padding-top: 1vh;
  padding-bottom: 1vh;
  text-align: right;
  margin: right;
  align-items: right;
  justify-content: right;
}

.fa-graduation-cap {
  padding-right: 1vh;
  font-weight: bold;
  background: white;
  /* color: #FC4C02; */
  color: rgb(51, 51, 51);
}
.fa-linkedin {
  font-weight: bold;
  background: white;
  /* color: #0e76a8; */
  color: rgb(51, 51, 51);
}

.fa-github {
    padding-right: 1vh;
    height: 10%;
    width: 10%;
    font-weight: bold;
    background: white;
    /* color: #553b08;  */
    color: rgb(51, 51, 51);
}

/* HEADSHOT */

.headshot-wrap {
  display: flex;
  height: 100%;
  width: 65%;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.headshot {
  height: 80%;
  width: 80%;
  border-radius: 50%;
}

/* Content on right div */

.Content-Container {
  float: right;
  justify-content: center;
  padding-right: 5vh;
  padding-left: 5vh;
  padding-top: 10vh;
  width: 65%;
  height: 100%;
  font-family: 'avenir';

  @media screen and (max-width: 720px) {
    padding-top: 0;
  }
}

.nav:hover {
  color: maroon;
}

h1 {
  margin: 0px;
  padding: 0px;
  color: var(--color-5);
  font-size: calc(11px + 2vh + 1vw);
}

h2 {
  display: flex;
  margin: 0px;
  padding: 0px;
  color: var(--color-4);
  font-size: calc(10px + .5vh + .5vw);
}

p {
  text-align: left;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


